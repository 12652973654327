.app-screen {
  perspective: 700px;
  display: flex;
  justify-content: center;
  position: relative;
  /*top: -100px;*/
  overflow: hidden;
  height: 1000px;
}

.fade1 {
  position: absolute;
  width: 100%;
  height: 100px;
  z-index: 1;
}

.fade1-top {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), white);
}
.fade1-bottom {
  background-image: linear-gradient(0deg, white, rgba(255, 255, 255, 0));
  bottom: 0;
}

.crawl {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(30deg);
  animation: crawl 200s linear infinite;
}
.crawl > img {
  border: 1px lightgray solid;
  border-radius: 10px;
}

.opacity-0 {
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
}

.opacity-1 {
  opacity: 1 !important;
}

@keyframes crawl {
  0% {
    top: 0;
    transform: rotateX(30deg) translateZ(0);
  }
  100% {
    top: -5300px;
    transform: rotateX(30deg) translateZ(-2600px);
  }
}

.hue-text {
  color: #f35626;
  background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.py-10 {
  padding-top: 15vh;
  padding-bottom: 15vh;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 250px) and (max-device-width: 750px) {
  .display-4 {
    font-size: 2rem !important;
  }

  h3 {
    font-size: 1.2rem !important;
  }

  .app-screen {
    height: 400px;
    perspective: 250px;
  }

  .container {
    padding-bottom: 5em;
    padding-top: 5em;
  }

  .pb-5,
  .py-5,
  .pt-5,
  .py-5 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
}
