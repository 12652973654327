html,
body,
#root,
#root > div {
  height: 100%;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

#navbar-dropdown {
  padding: 0;
}

/* #navbar-dropdown .dropdown-menu {
  width: 300px;
  margin-top: 5px;
} */

.object-fit-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.carousel-inner {
  height: 100%;
}

.carousel-inner .carousel-item {
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.carousel .carousel-control-next,
.carousel .carousel-control-prev,
.carousel .carousel-indicators {
  visibility: hidden;
}

.carousel:hover .carousel-control-next,
.carousel:hover .carousel-control-prev,
.carousel:hover .carousel-indicators {
  visibility: visible;
}

.icon:hover {
  color: var(--bs-secondary);
}
/* 
.modal-content {
  background-color: black !important;
} */

.btn-push:active {
  transform: scale(0.9);
  transition-duration: 0.05s;
  box-shadow: none !important;
}

.btn-push {
  transition: transform ease-in-out 0.1s;
  /* border-radius: 8px; */
}

.zoom-shadow {
  transition: all ease-in-out 0.1s;
  border-radius: 8px;
}

.zoom-shadow:hover {
  transform: scale(1.01) translate3d(0, -1px, 0);
  box-shadow: 0 4px 8px gray;
}

.zoom-shadow:active {
  transform: scale(0.99);
  transition-duration: 0.05s;
  box-shadow: none;
}

.custom-loader-icon {
  animation: none;
  margin: 0 !important;
  border: none !important;
}
.custom-loader-icon > div {
  font-size: inherit !important;
}

.link-color-inherit {
  color: inherit;
  text-decoration: none;
}

.overlay-hover {
  transition: opacity ease-in 0.1s;
  cursor: pointer;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.85);
}
.overlay-hover:hover {
  opacity: 1;
}

.opacity-transition {
  transition: opacity 0.2s ease-in;
  opacity: 0;
}

.grayscale {
  filter: grayscale(1) !important;
}

.z-index-900 {
  z-index: 900;
}

.z-index-1000 {
  z-index: 1000;
}

.swal2-confirm {
  background-color: var(--bs-primary) !important;
}

.transform-transition {
  transform: scale(1);
  transition: transform 0.04s ease-in-out;
}

.transform-scale-0_6 {
  transform: scale(0.6) !important;
}

.dropdown-toggle-noarrow:after {
  display: none !important;
}
